<template>
    <div class="container h-100">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-5">
                <div class="authincation-content">
                    <div class="row no-gutters">
                        <div class="col-md-12">
                            <img class="w-100" src="/assets/images/Perfil_Negro.jpg" />
                        </div>
                        <div class="col-md-12">
                            <div class="auth-form">
                                <h4 class="text-center mb-4"> Accede a la plataforma </h4>
                                <login-form></login-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from './LoginForm.vue'
export default {
    components: { LoginForm },
}
</script>


