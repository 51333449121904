<template>
    <form @submit.prevent="onSubmit">
        <div class="form-group">
            <label class="mb-1">
                <strong>Correo electrónico</strong>
            </label>
            <input
                type="email"
                class="form-control"
                placeholder="example@enforma.com"
                v-model="form.email"
                required
            />
        </div>
        <div class="form-group">
            <label class="mb-1">
                <strong>Contraseña</strong>
            </label>
            <c-input-password
            v-model:password="form.password"
            placeholder="* * * * *"
            toggle
            ></c-input-password>
        </div>

        <div class="form-row d-flex justify-content-between mt-4 mb-2">
            <div class="form-group">
                <div class="custom-control custom-checkbox ml-1">
                    <input type="checkbox" class="custom-control-input" id="basic_checkbox_1" />
                    <label class="custom-control-label" for="basic_checkbox_1">Recordarme</label>
                </div>
            </div>
            <div class="form-group">
                <login-forgot-password></login-forgot-password>
            </div>
        </div>
        <div class="text-center">
            <button
                type="submit"
                :class="['btn btn-primary btn-block d-flex justify-content-center align-items-center', { 'not-allowed':loading}]"
                :disabled="loading"
            >
                <c-spinner :loading="loading" />
                <span class="ml-1">Entrar</span>
            </button>
        </div>
    </form>
</template>

<script>
import { reactive } from '@vue/reactivity';
import useLogin from "../../composables/auth/useLogin";
import CSpinner from '../../components/spinners/CSpinner'
import CInputPassword from '../../components/inputs/CInputPassword.vue';
import LoginForgotPassword from './LoginForgotPassword.vue';
export default {
    components: {
    CSpinner,
    CInputPassword,
    LoginForgotPassword
},
    setup() {
        const form = reactive({
            email: '',
            password: '',
        })

        const { loading, login } = useLogin()

        const onSubmit = async () => {
            loading.value = true
            await login(
                form.email,
                form.password
            )
            loading.value = false
        }

        return {
            form,
            onSubmit,
            loading,
        }
    }
}
</script>

<style>
.not-allowed {
    cursor: not-allowed;
}
</style>
