<template>
  <a
    href="#"
    data-target="#modalForgotPassword"
    data-toggle="modal"
    @click="openModal"
    >Olvidaste tu contraseña?</a
  >

  <teleport to="#modal-wrapper">
    <c-modal
      id="modalForgotPassword"
      title_modal="Solicitud de recuperación de contraseña"
      @onSubmit="submitForm"
      submitText="Enviar"
      :btnSubmit="loading"
      :btnSubmitLoading="loading"
      :customValidate="false"
    >
      <template #modalBody>
        <div class="col-md-12">
          <p>
            ¿Olvidaste tu contraseña? No hay problema. Simplemente díganos su
            dirección de correo electrónico y le enviaremos un enlace para
            restablecer la contraseña que le permitirá elegir una nueva.
          </p>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label> <i class="fa fa-envelope"></i> Correo electrónico </label>
            <input
              type="email"
              class="form-control"
              name="email"
              v-model.trim="form.email"
              placeholder="example@enforma.com"
              required
            />
          </div>
        </div>
      </template>
    </c-modal>
  </teleport>
</template>

<script>
import { reactive } from "@vue/reactivity";
import CModal from "../../components/modals/CModal.vue";
import { sendPasswordRecoveryPetition } from "../../services/auth/authServices";
import { ref } from "@vue/reactivity";
export default {
  components: { CModal },
  setup() {
    const form = reactive({
      email: "",
    });
    const loading = ref(false);

    const submitForm = async () => {
      loading.value = true;
      const response = await sendPasswordRecoveryPetition(form);
      if (response.status < 300) {
        Swal.fire("Hecho!", response.message, "success").then(() => {
          $("#modalForgotPassword").modal("toggle");
        });
      } else if (response.status === 422) {
        Swal.fire("Error!", response.message, "error").then(() => {
          $("#modalForgotPassword").modal("toggle");
        });
      } else {
        Swal.fire("Error!", response.message, "error").then(() => {
          $("#modalForgotPassword").modal("toggle");
        });
      }
      loading.value = false;
    };

    const openModal = () => {
      form.email = "";
    };

    return {
      form,
      loading,
      openModal,
      submitForm,
    };
  },
};
</script>

<style>
</style>
